<template>
  <div class="formEditor">
    <!-- <addElement index="top"/> -->
    <div class="flix-flex flix-gap-10 ">
      <div
        class="flix-flex flix-flex-inline flix-mt-20"
        style=""
        v-for="(element, index) in $store.state.business.unsaved.form"
        :key="element.id"
        :style="{ width: (element.columnName / 12) * 100 - 5 + '%' }"
      >
        <div style="padding: 5px 0">
          <addElementInline :index="index + 1" />
        </div>
        <editMenu :id="index" style="width: 30px; margin-right: 10px" />
        <transition name="pageSwitch">
          <component
            :is="getEdit(element, index)"
            :data="element"
            style="flex: 1; width: 100%; padding-top: 5px"
          />
        </transition>
      </div>
    </div>
    <!-- <addElement index="bottom"/> -->
  </div>
</template>
<script>
export default {
  components: {
    addElementInline() {
      return import("./addElementInline");
    },
    editMenu() {
      return import("./editMenu");
    },
    addElement() {
      return import("./addElement");
    },
    headerView() {
      return import("./parts/header");
    },
    paragraphView() {
      return import("./parts/paragraph");
    },
    textView() {
      return import("./parts/text");
    },
    textareaView() {
      return import("./parts/textarea");
    },
    termsView() {
      return import("./parts/terms");
    },
    agbView() {
      return import("./parts/agb");
    },
    checkboxView() {
      return import("./parts/checkbox");
    },
    radioView() {
      return import("./parts/radio");
    },
    groupView() {
      return import("./parts/group");
    },

    headerEdit() {
      return import("./editor/header");
    },
    termsEdit() {
      return import("./editor/terms");
    },
    paragraphEdit() {
      return import("./editor/paragraph");
    },
    textEdit() {
      return import("./editor/text");
    },
    textareaEdit() {
      return import("./editor/textarea");
    },
    agbEdit() {
      return import("./editor/agb");
    },
    checkboxEdit() {
      return import("./editor/checkbox");
    },
    radioEdit() {
      return import("./editor/radio");
    },
    groupEdit() {
      return import("./editor/group");
    }
  },
  props: {},
  data() {
    return {
      form: this.$store.state.business.unsaved.form,
      updateKey: 0
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getEdit(element, index) {
      if (this.$store.state.form.edit === index) {
        return element.type + "Edit";
      }

      return element.type + "View";
    }
  }
};
</script>
<style lang="sass" scoped></style>
